<template>
  <div>
    <!-- Filters -->
    <app-collapse hover>
      <app-collapse-item title="Filters">
        <compensatory-off-application-list-filters
          :status-filter.sync="statusFilter"
          :status-options="compensatoryOffApplicationStatusOption"
          :created-by-filter.sync="createdByFilter"
          :employee-options="createdByFilterOptions"
          :manager-filter.sync="managerFilter"
          :manager-options="managerOptions"
        />
      </app-collapse-item>
    </app-collapse>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="
                compensatoryOffApplicationsMetaData.length &&
                $can('Zircly Admin')
              "
              class="mr-2"
              v-model="isDeleteMultipleCandChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group
                v-if="selectedCompensatoryOffApplications.length == 0"
                class="input-group-merge"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Name"
                />
              </b-input-group>

              <b-button
                v-if="
                  $can('compensatoryOffApplication_create') &&
                  selectedCompensatoryOffApplications.length == 0
                "
                variant="primary"
                :to="{ name: 'add-compensatory-off-application' }"
              >
                <span class="text-nowrap d-none d-md-flex">Apply for Comp Off</span>
		<span class="text-nowrap d-md-none">Apply</span>
              </b-button>
              <b-button
                pill
                v-b-modal.modal-review-application
                v-if="
                  $can('Zircly Admin') &&
                  selectedCompensatoryOffApplications.length != 0
                "
                class="float-right"
                variant="info"
                :disabled="selectedCompensatoryOffApplications.length == 0"
                >Review {{ selectedCompensatoryOffApplications.length }} Comp
                Off Application(s)</b-button
              >

              <b-modal
                id="modal-review-application"
                ref="modalReviewApplication"
                hide-footer
                centered
                @hidden="resetModal"
              >
                <template #modal-header="{ close }">
                  <h2 class="text-center">Review Applications</h2>
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    size="32"
                    @click="close()"
                  />
                </template>

                <div v-if="itemHashId != ''" class="mb-2">
                  <h2>
                    {{ itemUsername }}
                  </h2>
                  <div>{{ itemDates }}</div>
                  <div class="mt-1"><span class="h4 text-dark">Reason:</span> {{ itemReason }}</div>
                </div>

                <form
                  ref="form"
                  @submit.stop.prevent="handleSubmit"
                  class=""
                >
                  <b-form-group
                    label="Remark"
                    label-for="remark-input"
                    invalid-feedback="Remark is required"
                    :state="nameState"
                  >
                    <b-form-textarea
                      id="remark-input"
                      v-model="reviewer_reason"
                      :state="nameState"
                      placeholder="Remark"
                      required
                      rows="4"
                    ></b-form-textarea>
                  </b-form-group>

                  <div
                    v-if="itemHashId == ''"
                    class="d-flex justify-content-center"
                  >
                    <b-button
                      pill
                      variant="success"
                      class="mr-1"
                      :disabled="reviewer_reason == ''"
                      v-on:click="saveMassReview('Approved')"
                    >
                      Accept
                    </b-button>
                    <b-button
                      pill
                      variant="danger"
                      :disabled="reviewer_reason == ''"
                      v-on:click="saveMassReview('Rejected')"
                    >
                      Reject
                    </b-button>
                  </div>
                  <div
                    v-if="itemHashId != ''"
                    class="d-flex justify-content-center"
                  >
                    <b-button
                      pill
                      variant="success"
                      class="mr-1"
                      :disabled="reviewer_reason == ''"
                      v-on:click="saveReview('Approved')"
                    >
                      Accept
                    </b-button>
                    <b-button
                      pill
                      variant="danger"
                      :disabled="reviewer_reason == ''"
                      v-on:click="saveReview('Rejected')"
                    >
                      Reject
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
            <b-button
              v-if="$can('compensatoryOffApplication_delete') && false"
              class="float-right"
              variant="danger"
              :disabled="compensatoryOffApplicationsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete
                {{ compensatoryOffApplicationsDeleteCount }} Compensatory Off
                Application
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedCompensatoryOffApplications"
        name="selectedCompensatoryOffApplications"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchCompensatoryOffApplicationList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(user.name)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('compensatoryOffApplication_show')"
                :to="{
                  name: 'view-compensatory-off-application',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.user.name"
              >
                {{ data.item.user.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                v-b-tooltip.hover.v-default
                :title="data.item.user.name"
              >
                {{ data.item.user.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <template #cell(dates)="data">
            <span
              v-b-tooltip.hover.v-default
              :title="data.item.dates | formatDatesArray"
            >
              {{ data.item.dates | formatDatesArray | str_limit(26) }}
            </span>
          </template>

          <template #cell(from_date)="data">
            {{ data.item.from_date | formatDate }}
          </template>
          <template #cell(to_date)="data">
            {{ data.item.to_date | formatDate }}
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status_label }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(action)="data">
            <feather-icon
              @click="editRecord(data)"
              v-if="$can('compensatoryOffApplication_edit')"
              v-b-tooltip.hover
              title="Edit"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
            />
            <feather-icon
              @click="viewRecord(data)"
              v-if="$can('compensatoryOffApplication_show')"
              v-b-tooltip.hover
              title="View"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />

            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                class="btn-sm p-0"
                v-b-modal.modal-review-application
                v-if="
                  $can('compensatoryOffApplication_edit') &&
                  (data.item.reviewer.hashid == auth_user.hashid ||
                    $can('Zircly Admin'))
                "
                @click="confirmReview(data)"
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  size="13"
                  class="mr-50"
                />
                <span>Review</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="
                  $can('compensatoryOffApplication_delete') &&
                  data.item.user.hashid == auth_user.hashid &&
                  data.item.status != '1'
                "
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="selectedCompensatoryOffApplications.length == 0"
              v-model="currentPage"
              :total-rows="totalCompensatoryOffApplicationList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('compensatoryOffApplication_delete') && false"
              class="float-right"
              variant="danger"
              :disabled="compensatoryOffApplicationsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete
                {{ compensatoryOffApplicationsDeleteCount }} Compensatory Off
                Application</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import compensatoryOffApplicationStoreModule from "../compensatoryOffApplicationStoreModule";
import useCompensatoryOffApplicationList from "./useCompensatoryOffApplicationList";
import CompensatoryOffApplicationListFilters from "./CompensatoryOffApplicationListFilters.vue";
import moment from "moment";

export default {
  components: {
    CompensatoryOffApplicationListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-compensatory-off-applications";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedCompensatoryOffApplications = ref([]);
    const compensatoryOffApplicationsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        compensatoryOffApplicationStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchCompensatoryOffApplicationList,
      tableColumns,
      perPage,
      currentPage,
      totalCompensatoryOffApplicationList,
      compensatoryOffApplicationsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      resolveUserStatusVariant,
      compensatoryOffApplicationStatusOption,
      createdByFilter,
      managerFilter,
    } = useCompensatoryOffApplicationList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedCompensatoryOffApplications.value = [];
      if (val) {
        compensatoryOffApplicationsMetaData.value.forEach(
          (compensatory_off_application) => {
            selectedCompensatoryOffApplications.value.push(
              compensatory_off_application.hashid
            );
          }
        );
      }
    });

    watch(selectedCompensatoryOffApplications, (val) => {
      compensatoryOffApplicationsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchCompensatoryOffApplicationList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalCompensatoryOffApplicationList,
      // Extra Filters
      statusOptions,
      statusFilter,
      selectedCompensatoryOffApplications,
      compensatoryOffApplicationsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      compensatoryOffApplicationStatusOption,
      createdByFilter,
      compensatoryOffApplicationsMetaData,
      managerFilter,
    };
  },
  data() {
    return {
      auth_user: this.$cookies.get("userData"),
      createdByFilterOptions: [],
      reviewer_reason: "",
      itemUsername: "",
      itemDates: "",
      itemHashId: "",
      itemReason: "",
      nameState: null,
      managerOptions: [],
    };
  },
  created() {
    const self = this;
    if (!this.$can("Zircly Admin")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
     // this.fetchTeamList();
    }
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('employee_hash_id');
    this.createdByFilter = userId ? userId : ""
      this.fetchEmployeeList();
      this.fetchManagerList();
  },
  methods: {
    fetchManagerList() {
      this.$store
        .dispatch("app/descendantMangers")
        .then((res) => {
          this.managerOptions = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    resetModal() {
      const self = this;
      self.itemUsername = "";
      self.itemDates = "";
      self.itemHashId = "";
      self.itemReason = "";
      self.reviewer_reason = "";
    },
    fetchEmployeeList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.createdByFilterOptions = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchTeamList() {
      this.$store
        .dispatch("app/fetchTeamList")
        .then((res) => {
          if (res.data.data.length > 0) {
            this.createdByFilterOptions = res.data.data;
          }
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Fetching Team List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Team List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    editRecord(data) {
      this.$router
        .push(`/edit/compensatory-off-application/${data.item.hashid}`)
        .catch(() => {});
    },
    viewRecord(data) {
      this.$router
        .push(`/view/compensatory-off-application/${data.item.hashid}`)
        .catch(() => {});
    },
    confirmReview(data) {
      const self = this;
      self.itemUsername = data.item.user.name;
      self.itemDates =
        moment(String(data.item.from_date)).format("MMM Do YYYY") +
        " to " +
        moment(String(data.item.to_date)).format("MMM Do YYYY");
      self.itemHashId = data.item.hashid;
      self.itemReason = data.item.reason;
      self.reviewer_reason = data.item.reviewer_reason;
    },
    saveReview(status) {
      const self = this;
      const formData = new FormData();
      formData.append("reviewer_reason", self.reviewer_reason);
      formData.append("hashid", self.itemHashId);
      formData.append("status", status);
      self.$store
        .dispatch("app-compensatory-off-applications/saveReview", formData)
        .then((res) => {
          if (res.data.success) {
            this.refetchData();
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Review saved successfully",
            //     icon: "BellIcon",
            //     variant: "success",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Review saved successfully`,
                icon:'BellIcon',
                variant : 'success',
                hideClose: true,

              },
            },
            {
              timeout : 2000,
              position : 'bottom-center',
              toastClassName:"successful-info",
              hideProgressBar : true,

            }) 
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Review Update Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
            component: ToastificationContent,
            props: {
              title: `Review Update Failed`,
              icon:'BellIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
                })
          }
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Review Update Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Review Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
      this.$refs["modalReviewApplication"].hide();
    },
    saveMassReview(status) {
      const self = this;
      const formData = new FormData();
      formData.append("reviewer_reason", self.reviewer_reason);
      formData.append("hashids", this.selectedCompensatoryOffApplications);
      formData.append("status", status);
      let data = {};
      data.url = "compensatory_off_applications/mass-update-status";
      data.params = formData;

      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          if (res.data.success) {
            this.refetchData();
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Review saved successfully",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Review saved successfully',
                icon: 'EditIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Review Update Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Review Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Review Update Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Review Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
      this.$refs["modalReviewApplication"].hide();
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.user.name} Application.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedCompensatoryOffApplications.length} Comp Off Application(s), This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-compensatory-off-applications/removeMultipleCompensatoryOffApplications",
              this.selectedCompensatoryOffApplications
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedCompensatoryOffApplications = [];
              if (res.data.success) {
                this.refetchData();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Deleted Successfully.",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully.',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Comp Off Application(s) Deletion failed.",
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: res.data.message,
                //   },
                // });

                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comp Off Application(s) Deletion failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchCompensatoryOffApplicationsData() {
      console.log("Added Comp Off Application");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch(
          "app-compensatory-off-applications/removeCompensatoryOffApplication",
          id
        )
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comp Off Application Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Comp Off Application Deleted Successfully.",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comp Off Application Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Comp Off Application Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comp Off Application Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comp Off Application Deletion failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Comp Off Application Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Comp Off Application Deletion failed.`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
